<template>
    <modal ref="modalOrderCategories" titulo="Ordenar categoría" no-aceptar @adicional="create">
        <div class="row mx-0 my-3">
            <div class="col-12">
                <draggable tag="div" :list="categorias" class="custom-scroll overflow-auto" handle=".handle" @change="cambiarPosicion">
                    <div v-for="(item, t) in categorias" :key="t" class="d-middle my-3">
                        <i class="icon-dragable text-gris handle f-18" />
                        <img :src="item.imagen" alt="" width="40" height="40">
						<p class="col pl-2 pr-2">{{ item.nombre }}</p>
                    </div>
                </draggable>
            </div>
        </div>
    </modal>
</template>
<script>
import Categorias from '~/services/tiendas'
export default {
    data(){
        return {
			categorias: [],
        }
    },
    methods: {
        toggle(categorias){
            this.categorias = [];
            this.categorias = categorias;
            this.$refs.modalOrderCategories.toggle();
        },
        create(){
            this.$refs.modalOrderCategories.toggle();
        },
        async cambiarPosicion(event){
            try {
                let nuevaPosicion = event.moved.newIndex + 1
                let viejaPosicion = event.moved.oldIndex + 1

                let payload = {
                    nuevaPosicion: nuevaPosicion,
                    antiguaPosicion: viejaPosicion,
                    idCategoria: event.moved.element.id
                }

				const { data } = await Categorias.actualizarPosicion(payload)                
                if (data.success) this.notificacion('','Posición cambiada correctamente','success')

            } catch (error) {
				this.error_catch(error)
			}
        },
    }
}
</script>